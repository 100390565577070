/* styles/Footer.css */
.footer {
  display: flex;
  justify-content: space-between;
  padding: 24px 40px;
  background: #120E29;
}

.footer-left-side {
  display: flex;
  flex-direction: column;
}

.logo-section {
  display: flex;
  align-items: center;
}

.footer-logo {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}


.footer-text {
  margin-top: 10px;
  color: #C2C2C6;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.96px;
}

.privacy-text {
  color: #C2C2C6;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.96px;
}

.privacy-link {
  color: #C2C2C6;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.96px;
  text-decoration-line: underline;
}

.privacy-link:hover {
  text-decoration: underline;
}

.footer-right-side {
  display: flex;
  flex-direction: column;
}

.contact-text {
  color: #C2C2C6;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 157%; /* 18.84px */
  letter-spacing: 3.24px;
}

.email-text {
  color: #C2C2C6;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.96px;
}

/* ... Existing CSS ... */

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
    padding: 12px 20px;
  }

  .footer-left-side,
  .footer-right-side {
    align-items: center;
    text-align: center;
  }

  .footer-text,
  .contact-text,
  .email-text {
    font-size: 10px;
  }

  .privacy-link {
    font-size: 10px;
  }

  .logo-section {
    flex-direction: column;
    align-items: center;
    
  }

  .company-logo {
    height: 25px;
    width: 25px;
    display: block;
    margin-left: auto;
    margin-right: auto;

  }

  .company-name {
    margin-top: 5px;
  }

  .footer-text {
    margin-top: 5px;
  }
}
