
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 24px 40px;
  /* background-color: #fff; */
  /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); */
  z-index: 1000;  /* Ensures the navbar stays on top of other content */
  /* background: #04030A; */
  background: transparent;
  transition: background 0.3s ease-in-out; /* Adjust the timing as needed */
}
.navbar.solid-bg {
  background: #04030A;
}

.left-side {
  display: flex;
  align-items: center;
}

.company-logo {
  height: 40px;
  width: 40px;
  margin-right: 20px;
}


.right-side {
  display: flex;
  align-items: center;
}

.nav-item {
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 131.5%; /* 21.04px */
  letter-spacing: 1.28px;
  text-transform: capitalize;

  /* font-family: 'Poppins', sans-serif;  */
  margin-left: 40px;
  text-decoration: none;
  /* color: #FFF; */
  /* font-size: 1em; */
}

.nav-item:hover {
  text-decoration: underline;
  color: #DDD
}


/* Default hidden mobile menu */
.mobile-menu {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 100%; /* position it right below the navbar */
  right: 20px; /* Adjust if needed */
  background-color: #04030A;
  border-radius: 5px;
  z-index: 1010; /* To make sure menu appears over other content */
}

.mobile-menu .nav-item {
  margin: 10px 0;
  margin-left: 0; /* Reset the left margin for mobile */
}

nav .hamburger {
  background: none;
  border: none;
  color: #FFF;
  font-size: 24px; /* Adjust size if needed */
  cursor: pointer;
  display: none; /* Default hidden on larger screens */
}


/* Media queries for mobile responsiveness */

/* Adjust 768px breakpoint if needed */
@media (max-width: 768px) {
  .navbar {
    padding: 15px 20px;
  }

  .company-name {
    /* display: none;  */
  }

  .nav-item {
    font-size: 14px; /* Smaller font size for mobile */
  }

  .right-side .nav-item {
    display: none; 
  }

  .right-side .desktop-item {
    display: none; /* Hide desktop items by default on mobile */
  }

  .mobile-menu .mobile-item {
    display: none; /* Hide mobile items by default */
  }

  .mobile-menu.active .mobile-item {
    display: block; /* Display mobile items when the menu is active */
    padding: 2px 10px;
  }

  nav .hamburger,
  .mobile-menu {
    display: flex; /* Show hamburger and mobile menu on smaller screens */
  }

  .mobile-menu.active {
    display: flex;
  }
}

.desktop-item {
  display: none;
}

@media (min-width: 769px) {
  nav .hamburger,
  .mobile-menu {
    display: none;
  }

  .desktop-item {
    display: block;
  }
}
