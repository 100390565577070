/* styles/HomePage.css */
.main-div {
  padding: 0px;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
}

/* .hero-section {
  display: flex;
  flex-direction: column;
  color: white;
  height: 800px;
  position: relative;
}

.hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: left;
  color: white;
}

.planet-background {
  position: absolute;
  top: 0;
  right: 0px;
  z-index: -1;
  width: 100%;
  height: 1600px;
  flex-shrink: 0;
  background: url('../public/images/PlanetBackground.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.planet-background svg {
  position: absolute;
  right: 0;
  z-index: -2;
} */
.hero-section {
  display: flex;
  flex-direction: column;
  color: white;
  position: relative;
}

.hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: left;
  color: white;
  z-index: 1; /* Make sure the text content is above everything else */
  width: 657px;
  margin: 100px;
}
@media (max-width: 768px) {
  .hero-content {
    margin: 120px 20px;
    width: 300px;
  }
}


@keyframes rotateAndZoom {
  0% {
    transform: rotate(0deg) scale(1);
  }
  25% {
    transform: rotate(2deg) scale(1.05);  /* Rotation and a slight zoom-in */
  }
  50% {
    transform: rotate(4deg) scale(1.1);  /* More rotation and more zoom-in */
  }
  75% {
    transform: rotate(2deg) scale(1.05);  /* Rotation back and zoom-out a bit */
  }
  100% {
    transform: rotate(0deg) scale(1);     /* Return to initial state */
  }
}

.planet-background {
  position: absolute;
  top: 0;
  right: 0;
  left: 30px;
  z-index: -1;
  height: 1500px;
  background: url('../public/images/PlanetBackground.png');
  opacity: .75;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 100%;
  width: 100%;
  /* overflow-x: hidden; */

  /* Add animation */
  animation: rotateAndZoom 20s infinite;  /* 20s duration, but you can adjust */
}
@media (max-width: 768px) {
  .planet-background {
    height: 800px;
    left: 0px;
  }
}




/* .planet-background::before, */
/* .planet-background::after, */
.twinkle1::before,
.twinkle1::after,
.twinkle2::before,
.twinkle2::after,
.twinkle3::before,
.twinkle3::after,
.hero-content::before,
.hero-content::after {
  content: "";
  position: absolute;
  background-color: white;
  border-radius: 50%; /* make them circular */
  animation: twinkling 2s infinite alternate;
  z-index: 0; /* To ensure they appear below the content */
}

@keyframes twinkling {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.common-twinkle-properties {
  content: "";
  position: absolute;
  background-color: white;
  border-radius: 50%;
  animation: twinkling 1s infinite alternate;
  z-index: 0;
}

.hero-content::before {
  width: 4px;
  height: 4px;
  top: 78%;
  left: 28%;
  animation-delay: 0.3s;
}

.hero-content::after {
  width: 5px;
  height: 5px;
  top: 56%;
  left: 43%;
  animation-delay: 0.8s;
}

.twinkle1::before {
  width: 4px;
  height: 4px;
  top: 24%;
  left: 17%;
  animation-delay: 0.6s;
}

.twinkle1::after {
  width: 3px;
  height: 3px;
  top: 32%;
  left: 40%;
  animation-delay: 1s;
}

.twinkle2::before {
  width: 5px;
  height: 5px;
  top: 65%;
  left: 29%;
  animation-delay: 0.4s;
}

.twinkle2::after {
  width: 4px;
  height: 4px;
  top: 44%;
  left: 15%;
  animation-delay: 0.9s;
}

.twinkle3::before {
  width: 3px;
  height: 3px;
  top: 16%;
  left: 49%;
  animation-delay: 0.7s;
}

.twinkle3::after {
  width: 4px;
  height: 4px;
  top: 100%;
  left: 40%;
  animation-delay: 0.5s;
}




.strategies-section {
  padding: 50px 100px;
}
@media (max-width: 768px) {
  .strategies-section {
    padding: 20px 20px;
    text-align: center;
  }
  .strategies-section p {
    text-align: left;
  }
}


.pillars-section {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 50px 70px;
}
@media (max-width: 768px) {
  .pillars-section {
    flex-direction: column;  
    padding: 50px 20px;
  }
}

.pillar {
  flex: 1;
  padding: 0 30px;
  text-align: left;
  position: relative;
}

.pillar:not(:last-child)::after {
  /* Creates a thick vertical line to the right of each pillar, except the last one */
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 3px; /* Adjust this value for the thickness of the line */
  background-color: #DAAB68; /* Adjust this value for the color of the line */
}
@media (max-width: 768px) {
  .pillar {
    padding: 15px 0;  /* Adjust padding as needed */
  }
  .pillar h3 {
    text-align: center;
  }

  .pillar:not(:last-child)::after {
    content: none;  /* Remove the vertical line */
  }
}


.horizontal-divider {
  display: none;  /* Hide it by default */
}

@media (max-width: 768px) {
  .horizontal-divider {
    display: block;
    width: 100%;
    height: 3px; /* Adjust for your needs */
    background-color: #DAAB68; /* Your chosen color */
    margin: 10px 0; /* Add some margin for spacing */
  }
}






.performance-section {
  display: flex;
  justify-content: space-between;
  padding: 50px 100px;
}

@media (max-width: 768px) {
  .performance-section {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center the items */
    padding: 20px 20px;
  }
}

.performance-text {
  flex: 1;
  padding-right: 20px;
}
@media (max-width: 768px) {
  .performance-text h3 {
    text-align: center;
  }
}

.charts {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px; /* space between the charts */
}
@media (max-width: 768px) {
  .charts {
    width: 100%;
  }
}

.chart {
  height: 200px; /* example height */
  border: 1px solid #ccc; /* example border */
}

.a-arrow {
  color: #DAAB68;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 131.5%;
}


.quote-section {
  background: #120E29;
  padding: 50px 100px;
  color: #DAAB68;
  font-family: Taviraj;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 131.5%; /* 42.08px */
}
@media (max-width: 768px) {
  .quote-section {
    font-size: 18px;
    padding: 20px 20px;
  }
}

.quote-name {
  color: #DAAB68;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 131.5%; /* 26.3px */
}
@media (max-width: 768px) {
  .quote-name {
    font-size: 14px;
  }
}

.holdings-section {
  display: flex;
  /* flex-direction: ; */
  /* align-items: flex-start; */
  padding: 50px 70px;
}
@media (max-width: 768px) {
  .holdings-section {
    padding: 20px 20px;
  }
  .holdings {
    width: 50%;
  }
}

.values-header-section {
  padding: 100px 100px 0px 100px;
}

@media (max-width: 768px) {
  .values-header-section {
    padding: 60px 60px 0px 20px;
    text-align: center;
  }
}

.values-section {
  display: flex;
  align-items: flex-start;
  padding: 50px 70px;
}

.value {
  flex: 1;
  padding: 0 30px;
  text-align: left;
}

@media (max-width: 768px) {
  .values-section {
    flex-direction: column;  /* Stack them vertically */
    padding: 20px 20px;
  }

  .value {
    padding: 10px 0; /* Adjust padding as needed */
  }
}




.founder-section {
  padding: 50px 100px;
  max-width: 900px;
}

.founder-container {
  display: flex;
  align-items: center;
}

.founder-section img {
  max-width: 250px; /* example width */
  border-radius: 8px; /* 50% makes the image circular */
  margin: 10px 50px 0px 0px;
  opacity: .8;
}

/* Mobile styles */
@media (max-width: 768px) {
  .founder-section {
    padding: 50px 20px;
    text-align: center;
  }

  .founder-container {
    flex-direction: column;
    align-items: center;
    text-align: left;
  }

  .founder-section img {
    margin: 10px 0;
    height: 300px;
  }
}
