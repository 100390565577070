
/* App.css or index.css */
/* @import url('https://fonts.googleapis.com/css2?family=Taviraj:wght@400&display=swap'); */


/* App.css or index.css */
body, button, input, textarea, select {
  font-family: 'Taviraj', sans-serif; /* Assuming 'Taviraj' is available or imported */
  /* font-weight: 400; */
  color: #FFF;
  /* background: #04030A; */
  background: rgba(0,0,0, 1);
}

.company-name {
  color: #DAAB68;
  font-family: Taviraj;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 131.5%; /* 21.04px */
  letter-spacing: 4.88px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .company-name {
    margin-left: 20px;
    font-size: 12px;
  }
}

h1 {
  color: #FFF;
  font-family: Taviraj;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 131.5%; /* 105.2px */
}

h2 {
  color: #FFF;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.92px;
}

h3 {
  color: #DAAB68;
  font-family: Taviraj;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 131.5%; /* 31.56px */
  letter-spacing: 7.32px;
  text-transform: uppercase;
}

p {
  color: #EAEAEA;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.28px;
}

h4 {
  color: #FFF;
  font-family: Taviraj;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 131.5%; /* 42.08px */
}

a {
  color: #DAAB68;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 131.5%; /* 26.3px */
  text-decoration-line: underline;
}

@media (max-width: 768px) {
  h1 {
    font-size: 38px;
  }
  h2 {
    font-size: 16px;
  }
  
  h3 {
    font-size: 16px;
  }
  
  p {
    font-size: 14px;
  }
  
  h4 {
    font-size: 28px;
  }

  a {
    font-size: 16px;
  }
}


.privacy-and-terms {
  /* margin-top: 150px; */
  /* background-color: red; */

}

